import { Component } from 'vue-property-decorator'
import TermlyMixin from 'booksprout-app/src/components/mixins/termly'

import { SET_META } from 'booksprout-app'
import { WebsitePaths } from '../../../router/path.constants'

@Component
export default class CookiePolicy extends TermlyMixin {
  websitePaths = { ...WebsitePaths }

  created () {
    void this.$store.dispatch(SET_META, {
      title: 'Cookie Policy',
      canonicalUrl: window.location.origin + this.websitePaths.cookiePolicy
    })
  }
}
