import { Component } from 'vue-property-decorator'
import { CommonBaseMixin } from './base'

@Component
export default class TermlyMixin extends CommonBaseMixin {
  contentIsLoaded = false

  isLoaded () {
    setTimeout(() => {
      this.contentIsLoaded = true
    }, 350)
  }
}
